<template>
  <div class="program-content-main-container">
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>학습 프로그램</h2>
          </div>
          <div class="studybox_h3_box">
            <div class="studybox_h3_box_in">
              <div style="float:left;"><img src="/image/common/top_title_wood_bg02.png" /></div>
              <div style="float:left;margin-top:-4px;"><h3>Moonkkang Talk</h3></div>
              <div style="float:left;"><img src="/image/common/top_title_wood_bg04.png" /></div>
            </div>
          </div>
          <section class="study_myinfo_list_area" >
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <ul class="program_talk_top_btn">
                  <li>체계적인<br />레벨</li>
                  <li>분명한<br />학습목표</li>
                  <li>풍부한<br />학습꺼리</li>
                  <li>'HOW' 중심의<br />Teaching 자료</li>
                  <li>직접 확인할 수 있는<br />화상수업</li>
                </ul>
                <ul class="program_talk_bottom_btn">
                  <li><p>LEVEL</p></li>
                  <li><p>GOAL</p></li>
                  <li><p>LEARNING</p></li>
                  <li><p>TEACHING</p></li>
                  <li><p>VIDEO LESSON</p></li>
                </ul>
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'program' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'Program',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  }
}
</script>

<style scoped>

</style>
